document.addEventListener('DOMContentLoaded', function () {
  const sections = document.querySelectorAll('[data-form]');
  sections.forEach((section) => {
    const formConfig = JSON.parse(section.getAttribute('data-form'));
    createForm(formConfig);
  });
});

function createForm(config) {
  const container = document.getElementById(config.targetId);
  if (container) {
    hbspt.forms.create({
      region: config.region,
      portalId: config.portalId,
      formId: config.formId,
      target: `#${config.targetId}`,
      cssClass: config.cssClass,
      submitButtonClass: config.submitButtonClass,
      redirectUrl: config.redirectUrl,
      translations: {
        fr: {
          required: 'Champ obligatoire.',
          phoneInvalidCharacters:
            'CE CHAMP NE PEUT CONTENIR QUE DES CHIFFRES, ET +()-. OU X.',
          invalidEmailFormat: 'Veuillez saisir une adresse e-mail valide.'
        }
      },
      onFormReady: function (form) {
        const labels = form.getElementsByTagName('label');
        const inputs = form.getElementsByClassName('hs-form-field');

        const formInputs = form.querySelectorAll(
          'input[type="text"], input[type="email"], input[type="tel"], select, textarea'
        );

        const textArea = form.querySelector('textarea');
        textArea.rows = 5;

        for (let i = 0; i < labels.length; i++) {
          labels[i].classList.add('done-section-hubspot-form-group-label');
        }

        for (let i = 0; i < inputs.length; i++) {
          inputs[i].classList.add('done-section-hubspot-form-group');
        }

        formInputs.forEach(function (input) {
          const label = form.querySelector(`label[for="${input.id}"]`);

          function updateLabel() {
            if (input.value !== '' || document.activeElement === input) {
              label.classList.add('active');
            } else {
              label.classList.remove('active');
            }
          }

          updateLabel();

          input.addEventListener('focus', updateLabel);
          input.addEventListener('blur', updateLabel);
          input.addEventListener('input', updateLabel);
        });
      },

      onFormSubmit: function (form) {
        const emailInput = form.querySelector('input[name="email"]');
        const emailValue = emailInput?.value;

        if (emailValue) {
          localStorage.setItem('emailInput', emailValue);

          window.location.href = redirectUrl;
        } else {
          throw new Error('email not found');
        }
      }
    });
  } else {
    console.error(`Container with ID '${config.targetId}' not found.`);
  }
}
